import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Directions } from './Directions'

export interface Props {
  address?: string
  directions?: string[]
  googleMapsURL?: string
  image?: ImageProps
  languageCode: string
  siteName?: string
  title?: string
}

export const Map = memo(function Map({
  address,
  directions,
  googleMapsURL,
  image,
  languageCode,
  siteName,
  title,
}: Props) {
  return (
    <Container row wrap stretch tag="section">
      <MapContainer>
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      </MapContainer>
      <Wrapper>
        {siteName ? <SiteName>{siteName}</SiteName> : null}
        {address ? <Address>{address}</Address> : null}
        {googleMapsURL ? (
          <CTA
            label={useVocabularyData('open-the-map', languageCode)}
            URL={googleMapsURL}
            rel="noreferrer"
            target="_blank"
            variant="invert"
          />
        ) : null}
      </Wrapper>
      {directions ? (
        <DirectionsWrapper>
          <Title>{title}</Title>
          <Directions list={directions} />
        </DirectionsWrapper>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 7.5rem auto 12.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding-bottom: 7.5rem;

  @media (max-width: 1023px) {
    margin: 3.75rem auto 6.25rem;
    padding-bottom: 6.25rem;
  }

  @media (max-width: 767px) {
    margin: 3.75rem auto 5rem;
    padding-bottom: 3.75rem;
  }
`

const MapContainer = styled.div`
  width: 100%;
  cursor: pointer;
  height: 55.555vh;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out !important;
  }
`

const Wrapper = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: -3.75rem auto 0;
  padding: 3.75rem 13.194vw;
  max-width: 51.5rem;
  position: relative;
  text-align: center;
  z-index: 2;

  @media (max-width: 1023px) {
    margin: -5.625rem auto 0;
    padding: 1.875rem;
    width: calc(100% - 3.75rem);
  }
`

const SiteName = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.375rem;
  margin-bottom: 1.25rem;

  @media (max-width: 1023px) {
    margin-bottom: 1rem;
  }

  @media (max-width: 767px) {
    font-size: 1.625rem;
    line-height: 2rem;
  }
`

const Address = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;

  @media (max-width: 767px) {
    font-size: 0.875rem;
    line-height: 1.75rem;
  }
`

const CTA = styled(Button)`
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }

  @media (max-width: 767px) {
    margin-top: 1.25rem;
  }
`

const DirectionsWrapper = styled.div`
  width: 100%;
  margin-top: 7.5rem;

  @media (max-width: 1023px) {
    margin-top: 5.625rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.25rem;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 1.625rem;
    line-height: 2rem;
  }
`
