import styled from '@emotion/styled'
import React, { memo } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

export interface Props {
  list: string[]
}

export const Directions = memo(function Directions({ list }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      <Tabs>
        <TabList>
          {list.map((item: any, index) => (
            <Tab key={index}>{item.title}</Tab>
          ))}
        </TabList>

        {list.map((item: any, index) => (
          <TabPanel key={index}>
            {item.description ? (
              <TabText dangerouslySetInnerHTML={{ __html: item.description }} />
            ) : null}
          </TabPanel>
        ))}
      </Tabs>
    </Container>
  )
})

const Container = styled.div`
  max-width: 51.5rem;
  margin: 2.5rem auto 0;

  .react-tabs {
    margin-top: 2.5rem;
    .react-tabs__tab-list {
      display: flex;
      justify-content: space-between;
      margin: 0;
    }
    .react-tabs__tab {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 3rem;
      border: 0.0625rem solid
        ${({ theme }) => theme.colors.variants.neutralLight2};
      color: ${({ theme }) => theme.colors.variants.neutralDark3};
      cursor: pointer;
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: 0.2rem;
      line-height: 1.1875rem;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      transition: 0.2s ease-out;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        color: ${({ theme }) => theme.colors.variants.neutralDark2};
      }
      &.react-tabs__tab--selected {
        background: ${({ theme }) => theme.colors.variants.neutralDark4};
        color: ${({ theme }) => theme.colors.variants.neutralLight4};
        pointer-events: none;
      }

      @media (max-width: 767px) {
        letter-spacing: 0.1rem;
        height: 2.5rem;
      }

      @media (max-width: 359px) {
        letter-spacing: 0;
        height: 2.5rem;
        font-size: 0.8125rem;
      }
    }
    .react-tabs__tab-panel {
      display: none;
      &.react-tabs__tab-panel--selected {
        display: block;
        animation: fadeInPanel 0.6s;
      }
    }

    @keyframes fadeInPanel {
      from {
        opacity: 0;
        transform: translateY(2.25rem);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @media (max-width: 1023px) {
    width: calc(100% - 3.75rem);
  }
`

const TabText = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 2.5rem;
  padding: 0 2.8125rem;

  @media (max-width: 1023px) {
    line-height: 1.75rem;
    margin-top: 1.875rem;
    padding: 0 1.875rem;
  }
  @media (max-width: 479px) {
    padding: 0;
  }
`
